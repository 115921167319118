.bg-img {
    background: url("../img/bg.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

iframe#webpack-dev-server-client-overlay {
    display: none !important;
}
